<template>
<div style="height: 100%">
  <div v-if="type === 'company'" class="company">
    <div class="companyheader-bar">
      <img src="../../../../../assets/images/logo-bimcc.png" class="logo-icon-class">
      <div>{{systemName}}</div>
    </div>

    <div class="companymain-content">
      <div class="title-name">
          请选择您加入的企业
      </div>
      <div class="enter-role">
        <template v-if="roleListCampany.length !== 0">
          <div v-for="(item,index) in roleListCampany" :key="index" class="img-box" @click="selectRole(item,index)" v-show="item.company_type !== '外部企业'">
              <p class="arrow" ><i class="el-icon-arrow-down" style="font-size:24px"/></p>
              <div class="box-content">
                  <img :src="item.logo ? item.logo : defaultLogo">
                  <div class="type">{{item.company_type.trim() ? item.company_type : '未知类型'}}</div>
                  <div class="name">{{item.name}}</div>
              </div>
          </div>
      </template>
      <p v-else style="font-size: 18px">暂无企业 , 请登录测试项目加入企业！</p>
      </div>
    </div>
  </div>

  <div v-if="type==='selectRole'" class="select-role">
    <div class="header-bar" style="background-color: #fff !important">
      <span
        class="logo-icon"
        :style="{ backgroundImage: 'url(\'' + index_logo + '\')' }"
      ></span>
      <span>{{ systemName1 }}</span>
    </div>
  </div>
</div>
</template>

<script>
import {userUseCompany,userUserProject} from '@/api/userInfo'
export default {
    data(){
      return {
        systemName:'BIMCC数字建造平台',
        defaultLogo: require("@/assets/images/logo-min1.jpeg"),
        type:'company',
        index_logo: '//static.bimcc.com/logo/logo1.png',
        systemName1: 'BIMCC数字建造平台', //系统名字
        roleList: [
          {
            icon: 'ios-filing',
            text: '公司',
            value: 'company',
          },
          {
            icon: 'ios-bookmarks',
            text: '项目',
            value: 'project',
          },
        ],
      }
    },
    computed:{
      roleListCampany(){
        return JSON.parse(localStorage.getItem('userInfo')).saas
      }
    },
    methods:{
        selectRole(item){
          userUseCompany({company_id:item.id}).then((r)=>{
            if(r.status &&r.status!==200){
              return
            }
            this.$store.commit('setSaasTargetArchi',r?.data?.data?.department_info)
            localStorage.setItem('company_id',r?.data?.data?.company_id)
            this.$router.push('/home');
          })
        },

        select(item){
          let param 
          if(item.type == 1){
            param = {
              company_id:item.company_id,
              type : item.type,
              rent_company_id:item.rent_company_id
            }
          }else if(item.type === 2){
            param = {
              project_id:item.project_id,
              type : item.type,
              rent_company_id:item.rent_company_id
            }
          }
          userUserProject(param).then(r=>{
            console.log(r);
          })
        },

        goback(){
          this.type = 'company'
        },

        handleImgUrl(value){
            return `//static.bimcc.com/images/web_${value}.png`;
        }
    }
}
</script>
<style lang="less" scoped>
@import "./selectRole.less";
    .company{
        background-size:100% 100% ;
        background: #F2F4F5;
        width: 100%;
        height: 100%;
        color: #2C374D;
    }
    .companyheader-bar{
        background: #fff;
        color: #2C374D;
        -webkit-box-shadow: 2px 0 3px 0 rgba(1, 8, 22, 0.06);
        -moz-box-shadow: 2px 0 3px 0 rgba(1, 8, 22, 0.06);
        box-shadow: 2px 0 3px 0 rgba(1, 8, 22, 0.06);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 12px;
        box-sizing: border-box;
        .logo-icon-class{
          display: block;
          width: 158px;
          padding-right: 16px;
        }
        &>div{
          padding-left: 16px;
          border-left: 1px solid rgba(189, 192, 199, 0.6);
          font-size: 24px;
        }


        .logo-icon{
          float: left;
            width: 158px;
            height: 44px;
            margin: 10px;
            background: url('../../../../../assets/images/logo-bimcc.png') no-repeat;
            background-size: 100% 100%;
        }
        
        .language{
           // position: absolute;
            float: right;
            right: 360px;
            margin: 20px 0;
            width: 70px;
            height: 20px;
            border: 1px solid #BDC0C7;
            border-radius: 14px;
            font-size: 12px;
            line-height: 18px;
            padding: 4px 0;
            text-align: center;
            color: #BDC0C7;
            .activeLang{
                color: #25282E;
            }
            span{
                cursor: pointer;
            }
        }
    }
    .companymain-content{
        // margin: 178px 131px;
        box-sizing: border-box;
        overflow: hidden;
        .title-name{
            font-size: 30px;
            line-height: 30px;
            color: #25282E;
            font-weight: 600;
            text-align: center;
            margin-bottom: 104px;
            position: relative;
            .back-icon{
                position: absolute;
                left: 0;
                bottom: -8px;
                width: 44px;
                height: 44px;
                cursor: pointer;
                background: url(//static.bimcc.com/images/web_login/web_back_1920.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        .enter-role{
            display: flex;
            // justify-content: center;
            justify-content: space-evenly;
            .img-box{
                width: 240px;
                height: 320px;
                cursor: pointer;
                position: relative;
                background: #fff;
                &:not(:last-child){
                    margin-right: 32px;
                }
                &:hover{
                    transform:translateY(-8px);
                    transition: all .1s;
                    -webkit-box-shadow: 2px 0 3px 0 rgba(1, 8, 22, 0.06);
                    -moz-box-shadow: 2px 0 3px 0 rgba(1, 8, 22, 0.06);
                    box-shadow: 0 0 8px 8px rgba(1, 8, 22, 0.06);
                    .arrow{
                        display: inline-block;
                    }
                }
                .arrow{
                    display: none;
                    position: absolute;
                    top: -24px;
                    left: 'calc(50% - 12px)';
                }
                .box-content{
                    padding: 60px 40px;
                    height: 100%;
                    img{
                        width: 60px;
                        height: 60px;
                        border-radius: 8px;
                    }
                    .type{
                        font-size: 16px;
                        line-height: 16px;
                        margin: 44px 0 12px;
                        color: rgb(136, 138, 141);
                    }
                    .name{
                        font-size: 24px;
                    }
                }


            }
        }
    }
    // 判断小屏幕
    @media(max-width:1366px){
        .companyheader-bar{
            .logo-icon-class{
              display: block;
              width: 124px;
              padding-right: 12px;
            }
            &>div{
              padding-left: 12px;
              border-left: 1px solid rgba(189, 192, 199, 0.6);
              font-size: 18px;
            }
            .logo-icon{
                width: 124px;
                height: 38px;
                margin: 5px;
                background: url('../../../../../assets/images/logo-bimcc.png') no-repeat;
                background-size: 100% 100%;
            }
            .language{
                margin: 14px 0;
                font-size: 10px;
                line-height: 10px;
                right: 200px;
            }
        }
        .companymain-content{
            margin: 100px 204px;
            .title-name{
                margin-bottom: 80px;
            }
            .enter-role{
                display: flex;
                justify-content: center;
                .img-box{
                    width: 220px;
                    height: 280px;
                    &:not(:last-child){
                        margin-right: 24px;
                    }
                    .box-content{
                        padding: 40px 32px;
                        img{
                            width: 56px;
                            height: 56px;
                        }
                        .type{
                            font-size: 18px;
                            line-height: 18px;
                            margin: 48px 0 16px;
                            color: rgb(136, 138, 141);
                        }
                        .name{
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }


</style>